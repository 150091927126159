<!--
 * @Description: 确认开班
 * @Author: xiawenlong
 * @Date: 2021-04-28 16:25:37
 * @LastEditors: xiawenlong
 * @LastEditTime: 2021-12-13 19:33:17
-->
<template>
  <div class="class-offer">
    <el-row :gutter="20">
      <el-col :span="8">
        <el-card class="class-info">
          <div slot="header" class="clearfix">
            <span class="title">班级信息</span>
          </div>
          <div class="content">
            <div class="cover">
              <img :src="classInfo.pic" alt="" />
            </div>
            <p class="name">班级名称：{{ classInfo.className }}</p>
            <p class="teacher">
              班主任：<span v-for="(item, index) in classInfo.headTeacher" :key="index">{{
                item.studentName
              }}</span>
            </p>
            <p class="time">
              班级培训周期：{{ classInfo.classBeginTime }}~{{ classInfo.classEndTime }}
            </p>
          </div>
        </el-card>
      </el-col>
      <el-col :span="16">
        <el-card class="courseInfo">
          <div slot="header" class="clearfix">
            <span class="title">课程信息</span>
          </div>
          <el-table
            border
            height="240"
            stripe
            :data="settleCourseList"
            tooltip-effect="dark"
            style="width: 100%"
          >
            <el-table-column prop="courseName" label="课程名称"> </el-table-column>
            <el-table-column label="课程购买方式">
              <template slot-scope="scope">
                <el-select
                  v-model="scope.row.chargesItem"
                  :disabled="scope.row.isBuyMethodDisable"
                  placeholder="请选择"
                  value-key="buyMethod"
                  @change="changeBuyMethod"
                >
                  <el-option
                    v-for="(item, index) in scope.row.charges"
                    :key="index"
                    :label="item.name"
                    :value="item"
                  >
                  </el-option>
                </el-select>
              </template>
            </el-table-column>
            <el-table-column prop="studentTotal" label="员工数量" width="80" align="right">
              <template slot-scope="scope">
                {{ scope.row.chargesItem.studentTotal + '人' }}
              </template>
            </el-table-column>
            <el-table-column prop="studentTotal" label="书院库存" width="80" align="right">
              <template slot-scope="scope">
                {{ scope.row.chargesItem.buyMethod == 3 ? '-' : scope.row.chargesItem.itemStock }}
              </template>
            </el-table-column>
            <el-table-column prop="studentTotal" label="需购数量" width="80" align="right">
              <template slot-scope="scope">
                {{
                  scope.row.chargesItem.buyMethod == 3 ? '-' : scope.row.chargesItem.toSettleTotal
                }}
              </template>
            </el-table-column>
            <el-table-column label="课程单价" align="right">
              <template slot-scope="scope">
                <span>
                  <template v-if="scope.row.chargesItem.buyMethod == 3">-</template>
                  <template v-else
                    >￥{{ scope.row.chargesItem.price
                    }}{{ '元/' + ['年', '人/年'][scope.row.chargesItem.buyMethod - 1] }}</template
                  >
                </span>
              </template>
            </el-table-column>
            <el-table-column label="小结" align="right">
              <template slot-scope="scope">
                <span>￥{{ scope.row.chargesItem.amount }}元</span>
              </template>
            </el-table-column>
          </el-table>
          <div class="allPrice">总计：￥{{ orderPrice }}元</div>
        </el-card>
      </el-col>
    </el-row>
    <el-card class="studentTable">
      <div slot="header" class="clearfix">
        <span class="title">学员信息</span>
      </div>
      <div class="content">
        <el-table border stripe :data="studentList" tooltip-effect="dark" style="width: 100%">
          <el-table-column prop="studentName" label="学员名称"> </el-table-column>
          <el-table-column prop="mobile" label="手机号码"> </el-table-column>
          <el-table-column prop="cardNo" label="身份证/护照"> </el-table-column>
        </el-table>
        <el-pagination
          background
          :current-page="pager.current"
          :page-sizes="[10, 20, 30, 40]"
          :page-size="pager.size"
          layout="total, prev, pager, next, sizes, jumper"
          :total="pager.total"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
        >
        </el-pagination>
      </div>
    </el-card>
    <el-card class="payInfo">
      <div slot="header" class="clearfix">
        <span class="title">支付信息</span>
      </div>
      <div class="content">
        <div class="orderMoney">
          <div class="key">订单金额：</div>
          <div class="value">￥{{ orderPrice }}元</div>
        </div>
        <div class="payType">
          <div class="key">支付方式：</div>
          <div class="value">
            <img src="@/assets/class/wallet_icon.png" alt="" />
            <p>余额支付</p>
            <p>
              当前余额<span>￥{{ balanceAmount }}</span
              >元
            </p>
            <div class="btn" @click="toRecharge">充值</div>
          </div>
        </div>
        <div class="tip">注：确定开班之后班级人员课程不能变动，请核对好信息后开班</div>
        <el-button v-if="askLoading" :loading="askLoading" type="primary">正在询价</el-button>
        <el-button v-else type="primary" @click="submit">确定结算</el-button>
      </div>
    </el-card>
    <Recharge ref="Recharge"></Recharge>
  </div>
</template>
<script>
import {
  classInfo,
  classStudents,
  classSettleCourseList,
  classOffer,
  classAskPrice,
} from '@/api/class'
import { getAccountsMoney } from '@/api/college'
import to from 'await-to'
import Recharge from '../college/Accounts/commonents/Recharge'
export default {
  components: {
    Recharge,
  },
  data() {
    return {
      askLoading: false,
      classId: '',
      classInfo: {},
      studentList: [],
      settleCourseList: [],
      balanceAmount: '0.00',
      orderPrice: 0,
      pager: {
        total: 0,
        size: 10,
        current: 1,
      },
    }
  },
  mounted() {
    this.classId = this.$route.params.classId
    this.getClassInfo()
    this.getSettleCourseList()
    this.getClassStudents()
    this.getAccountsMoneyData()
  },
  methods: {
    accMul(arg1, arg2) {
      var m = 0,
        s1 = arg1.toString(),
        s2 = arg2.toString()
      try {
        m += s1.split('.')[1].length
      } catch (e) {
        // console.log(e)
      }
      try {
        m += s2.split('.')[1].length
      } catch (e) {
        // console.log(e)
      }
      return (
        (Number(s1.replace('.', '')) * Number(s2.replace('.', ''))) /
        Math.pow(10, m)
      ).toFixed(2)
    },
    toRecharge() {
      this.$refs.Recharge.show()
    },
    async getClassInfo() {
      const [res, err] = await to(classInfo({ classId: this.classId }))
      if (err) return this.$message.warning(err.msg)
      this.classInfo = res.data
    },
    async getSettleCourseList() {
      const [res, err] = await to(classSettleCourseList({ classId: this.classId }))
      if (err) return this.$message.warning(err.msg)
      this.settleCourseList = res.data
      this.settleCourseList.forEach(element => {
        // if (element.buyDetail == 1) {
        this.$set(element, 'chargesItem', element.charges[0])
        // }
      })
      this.askPrice()
    },
    async getClassStudents() {
      const { current, size } = this.pager
      const [res, err] = await to(classStudents({ current, size, classId: this.classId }))
      if (err) return this.$message.warning(err.msg)
      this.studentList = res.data.list
      this.pager.total = res.data.total
    },
    async getAccountsMoneyData() {
      const [res, err] = await to(getAccountsMoney())
      if (err) return this.$message({ type: 'error', message: err.msg })
      this.balanceAmount = res.data.balanceAmount
    },
    changeBuyMethod() {
      this.askPrice()
    },
    async askPrice() {
      const { classId } = this
      const courses = this.settleCourseList.map(item => ({
        buyMethod: item.chargesItem ? item.chargesItem.buyMethod : '',
        classCourseId: item.classCourseId,
      }))
      this.askLoading = true
      const [res, err] = await to(classAskPrice({ classId, courses }))
      this.askLoading = false
      if (err) return this.$message.warning(err.msg)
      this.orderPrice = res.data
    },
    submit() {
      this.$confirm(
        '确认开班之后，人员课程不能变动，请核对好信息后开班，您确定需要开班吗',
        '确定开班',
        {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
        },
      )
        .then(async () => {
          const { classId } = this
          const courses = this.settleCourseList.map(item => ({
            buyMethod: item.chargesItem ? item.chargesItem.buyMethod : '',
            classCourseId: item.classCourseId,
          }))
          if (courses.length == 0) {
            return this.$message.warning('课程不能为空')
          }
          const loading = this.$loading({
            lock: true,
            text: '结算中...',
            spinner: 'el-icon-loading',
            background: 'rgba(0, 0, 0, 0.7)',
          })
          const [, err] = await to(classOffer({ classId, courses }))
          loading.close()
          if (err) return this.$message.warning(err.msg)
          this.$message.success('开班成功')
          this.$router.push('/class/list')
        })
        .catch(() => {})
    },
    // 分页
    handleSizeChange(size) {
      this.pager.current = 1
      this.pager.size = size
      this.getClassStudents()
    },
    handleCurrentChange(current) {
      this.pager.current = current
      this.getClassStudents()
    },
  },
}
</script>
<style lang="scss" scoped>
.class-offer {
  .el-card {
    margin-bottom: 20px !important;
    &.class-info {
      height: 375px;
      .content {
        .cover {
          width: 282px;
          height: 145px;
          margin-bottom: 28px;
          img {
            @extend %width-height;
          }
        }
        p {
          height: 14px;
          font-size: 14px;
          color: #333333;
          margin-bottom: 16px;
        }
      }
    }
    &.courseInfo {
      height: 375px;
      .allPrice {
        font-size: 18px;
        color: #333333;
        text-align: right;
      }
    }
    &.payInfo {
      .content {
        > div {
          display: flex;
        }
      }
      .key {
        display: inline-block;
        font-size: 14px;
        width: 85px;
        color: #606266;
      }
      .value {
        flex: 1;
        display: inline-block;
      }
      .orderMoney {
        font-size: 14px;
        color: #ff4b36;
        margin-bottom: 16px;
      }
      .payType {
        display: flex;
        align-items: center;
        margin-bottom: 10px;
        .value {
          display: flex;
          align-items: center;
          width: 100%;
          height: 42px;
          background: #fff5f0;
          padding: 0 20px;
          img {
            width: 20px;
            height: 17px;
            margin-right: 8px;
          }
          p {
            margin-right: 36px;
            font-size: 14px;
            color: #333333;
            &:nth-of-type(2) {
              color: #979797;
              span {
                color: #ff4b36;
              }
            }
          }
          .btn {
            cursor: pointer;
            width: 61px;
            height: 24px;
            border: 1px solid #ff7b33;
            border-radius: 5px;
            text-align: center;
            font-size: 14px;
            color: #ff7b33;
          }
        }
      }
      .el-button {
        margin-left: 85px;
      }
    }
    .tip {
      font-size: 12px;
      margin-bottom: 20px;
      color: #ff4b36;
    }
  }
}
</style>
